<template>
    <div id="courseDetails">
        <div class="box">
            <div class="breadCrumbs">
                <span style="color: #999999;cursor: pointer;" @click="indexClick">首页</span>>
                <span style="color: #999999;cursor: pointer;" @click="courseClick">课程教学</span>>
                <span>课程介绍</span>
            </div>

            <div class="boxTwo">
                <div style="margin: 10px;">
                    <img style="height: 224px;width:394px;" :src="`${baseUrl}/${courseItem.coverPic}`" alt="">
                </div>

                <div style="margin: 0.8%;width: 80%;">
                    <p style="font-size: 1.3rem;font-weight: bold;padding-bottom: 5px;">{{ courseItem.courseName }}</p>
                    <div style="width: 80%;height: 120px;background-color: #F8F9FB;padding: 10px;">
                        <p style="font-size: 14px;padding-top: 10px;"><span style="font-size: 14px;color: #666666;">课程发布时间：
                            </span>{{ courseItem.publishTime }}</p>
                        <p style="font-size: 14px;padding-top: 20px;">
                            <span style="color: #666666;">来源组织： </span><span style="padding-right: 20px;">{{
                                courseItem.productionUnit }}</span>
                            <span style="color: #666666;">类别： </span><span style="padding-right: 20px;">{{
                                courseItem.courseType == 1 ? '高职' : '本科' }}</span>
                            <span style="color: #666666;">专业： </span><span style="padding-right: 20px;">{{
                                courseItem.majorName }}</span>
                            <span style="color: #666666;">课程： </span><span>{{ courseItem.courseName }}</span>
                        </p>
                        <p style="font-size: 14px;padding-top: 20px;">
                            <span style="color: #666666;">作者： </span><span style="padding-right: 20px;">{{
                                courseItem.producer }}</span>
                            <span style="color: #666666;">课程依据： </span><span> {{ courseItem.pursuant }} </span>
                        </p>
                    </div>
                    <el-button v-if="StudentOrTeacher == 2 && courObject.courseType == 1" disabled type="primary" round
                        style="background-color: #B1E3FF;border: none;margin-left: 10px;margin-top:10px;">已加入必修</el-button>
                    <el-button v-if="StudentOrTeacher == 2 && ifcourse == false" type="primary" round
                        style="background-color: #76C8F6;border: none;margin-left: 10px;margin-top:10px;"
                        @click="JoinAnElective(courseItem.id)">加入选修</el-button>
                    <el-button v-if="StudentOrTeacher == 2 && ifcourse == true && courObject.courseType == 2" type="primary"
                        round style="background-color: #B1E3FF;border: none;margin-left: 10px;margin-top:10px;"
                        @click="cancellation(courseItem.id)">已加入选修</el-button>

                    <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-top:10px;"
                        @click="courseIntroductionDetails">课程</el-button>
                </div>

            </div>
            <div class="boxThree">
                <div class="count">
                    <div style="float: left;margin: 15px auto;padding: 10px;">
                        <p><span style="font-size: 24px;font-weight: bold;color: #333333;">{{ courseItem.peopleCount
                        }}</span><br /><span style="font-size: 14px;font-weight: 400;color: #666666;">累计学习人数</span>
                        </p>
                    </div>
                    <div style="float: right;margin: 15px auto;margin-right: 20px;">
                        <img :src="require('@/assets/img/courseTechingimg/icon1.png')" alt="" srcset="">
                    </div>
                </div>
                <div class="count">
                    <div style="float: left;margin: 15px auto;padding: 10px;">
                        <p><span style="font-size: 24px;font-weight: bold;color: #333333;">{{ courseItem.coursewareIdCount
                        }}</span><br /><span style="font-size: 14px;font-weight: 400;color: #666666;">课件数量</span>
                        </p>
                    </div>
                    <div style="float: right;margin: 15px auto;margin-right: 20px;">
                        <img :src="require('@/assets/img/courseTechingimg/icon2.png')" alt="" srcset="">
                    </div>
                </div>
                <div class="count">
                    <div style="float: left;margin: 15px auto;padding: 10px;">
                        <p><span style="font-size: 24px;font-weight: bold;color: #333333;">{{ courseItem.courseVideoCount
                        }}</span><br /><span style="font-size: 14px;font-weight: 400;color: #666666;">视频数量</span>
                        </p>
                    </div>
                    <div style="float: right;margin: 15px auto;margin-right: 20px;">
                        <img :src="require('@/assets/img/courseTechingimg/icon3.png')" alt="" srcset="">
                    </div>
                </div>
                <div class="count">
                    <div style="float: left;margin: 15px auto;padding: 10px;">
                        <p><span style="font-size: 24px;font-weight: bold;color: #333333;">{{ courseItem.courseSectionCount
                        }}</span><br /><span style="font-size: 14px;font-weight: 400;color: #666666;">课程节数</span>
                        </p>
                    </div>
                    <div style="float: right;margin: 15px auto;margin-right: 20px;">
                        <img :src="require('@/assets/img/courseTechingimg/icon4.png')" alt="" srcset="">
                    </div>
                </div>
            </div>
            <div class="boxFour">
                <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                    <el-tab-pane label="课程详情" name="first">
                        <div>
                            <p style="font-size: 16px;font-weight: 600;color: #333333;">{{ courseItem.courseDetails }}</p>

                            <!-- <p style="font-size: 16px;font-weight: 600;color: #333333;">课程介绍</p>
                        <p style="font-size: 14px;font-weight: 400;color: #333333;padding-top: 10px;line-height: 20px;">本书是根据高职高专建筑力学与结构新课程标准及教学实践要求编写的, 充分汲取了高职高专和成人高校培养应用型人才方面取得的成功经验和教学成果.本书从高职高专培养目标和学生实际应用出发, 以适用、 够用为目标, 对理论力学、 材料力学、 结构力学３ 门课程内容做了相应的整合, 适应高职人才培养目标需要; 基于岗位职业能力和能力发展教学目标要求, 注重工程实践案例的运用, 将教学内容模块化, 便于采用项目教学法;内容简明扼要、 图文配合紧密, 运用现代网络技术, 可使用智能设备查看资料素材, 加深对课程内容的理解, 符合高等职业教育和专业建设发展的新要求.</p>
                        <p style="font-size: 16px;font-weight: 600;color: #333333;padding-top: 20px;">课程知识点</p>
                        <p style="font-size: 14px;font-weight: 400;color: #333333;padding-top: 10px;line-height: 20px;">全书共分10章，内容包括：第1章绪论，第2章平面杆件体系的几何组成分析，第3章静定结构的受力分析，第4章静定结构的位移计算，第5章力法，第6章位移法，第7章其他计算方法，第8章影响线，第9章矩阵位移法，第10章结构的动力计算。
                            <br>本书可作为普通高等院校土木工程专业结构力学课程的教材，也可作为相关专业、网络与成人教育、自学考试的教材，还可作为研究生入学考试、注册结构师基础考试、相关专业工程技术人员的参考书。</p> -->
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="课程目录" name="second">
                        <el-collapse v-model="activeNames" @change="handleChange">
                            <el-collapse-item title="" :name="index" v-for="(item, index) in CourseCatalog" :key="index">
                                <template #title>
                                    <span style="font-size: 16px;font-weight: 500;color: #333333;padding-left: 20px;">第{{
                                        index+=1 }}章
                                        {{ item.catalogueName }}</span>
                                </template>
                                <div style="margin-left: 80px;margin-top: 10px;"
                                    v-for="(itemChild, indexChild) in item.child" :key="indexChild">
                                    <p @click="WatchCourse(itemChild)"><span>第{{ indexChild+=1 }}节 {{ itemChild.catalogueName
                                    }}</span>
                                        <img style="vertical-align: middle;cursor: pointer;"
                                            :src="require('@/assets/img/courseTechingimg/palyer.png')" alt="">
                                    </p>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </el-tab-pane>
                    <el-tab-pane label="教师团队" name="third">
                        <div style="padding: 10px;display: table-cell;">
                            <p style="font-weight: 600;padding-left: 20px;">课程负责人</p>
                            <div style="display: table-cell;" v-for="(item, index) in CourseManager" :key="index">
                                <el-avatar style="text-align: center;margin-top: 10px;"
                                    :src="`${baseUrl}/${item.teacherPic}`" :size=100></el-avatar>
                                <p style="text-align: center;">{{ item.teacherName }}</p>
                            </div>
                        </div>
                        <div style="padding: 10px;margin-top: 20px;">
                            <p style="font-weight: 600;padding-left: 20px;">教师团队</p>
                            <div style="display: table-cell;padding-right: 20px;" v-for="(item, index) in CurriculumTeacher"
                                :key="index">
                                <el-avatar style="text-align: center;margin-top: 10px;"
                                    :src="`${baseUrl}/${item.teacherPic}`" :size=100></el-avatar>
                                <p style="text-align: center;">{{ item.teacherName }}</p>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="课程评价" name="fourth">
                        <div style="display: flex;justify-content: space-between;">
                            <div style="float: left;height:480px;overflow-y: auto;" class="scoreClass">
                                <div style="margin-bottom: 20px;" v-for="(item, index) in userScore" :key="index">
                                    <p>
                                        <el-avatar style="text-align: center;vertical-align: middle;"
                                            :src="`${baseUrl}/${item.userPic}`"></el-avatar>
                                        <span style="padding-left: 10px;color: #666666;">{{ item.realName }}</span>
                                        <span style="padding-left: 10px;color: #666666;">{{ item.insertTime }}</span>
                                        <span>
                                            <el-rate style="margin-top: 8px;margin-left: 20px;display: initial;"
                                                v-model="item.score" disabled text-color="#ff9900">
                                            </el-rate>
                                        </span>
                                    </p>
                                    <p style="padding-top: 10px;width: 50%;color: #333333;">{{ item.content }}</p>
                                </div>
                            </div>
                            <div>
                                <el-button type="primary" round
                                    style="background-color: #76C8F6;border: none;margin-left: 10px;float: right;"
                                    @click="wantEvaluate">我要评价</el-button>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="课程思政" name="fifth">
                        <div style="display: flex;justify-content: space-between;">
                            <div style="float: left;height:480px;overflow-y: auto;" class="scoreClass">
                                <div style="margin-bottom: 20px;" v-for="(item, index) in logyPoliticsData?.showContent"
                                    :key="index">
                                    <div style="display:flex;justify-content:space-between">
                                        <h5 style="height: 16px;
                                        font-size: 16px;
                                        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                                        font-weight: 500;
                                        color: #333333;
                                        line-height: 24px;margin-bottom:16px;">{{ item.title }}</h5>
                                    </div>
                                    <p style="font-size: 14px;
                                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                    font-weight: 400;
                                    color: #333333;
                                    line-height: 25px;" v-html="item.content"></p>
                                </div>

                                <div v-for="(item,index) in logyPoliticsData?.csMajorClassRoomList" :key="index" style="margin-bottom:20px;">
                                    <div style="display:flex;justify-content:space-between">
                                        <h5 style="height: 16px;
                                        font-size: 16px;
                                        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                                        font-weight: 500;
                                        color: #333333;
                                        line-height: 24px;margin-bottom:16px;cursor:pointer;">{{ item.mcrName }}</h5>
                                    </div>
                                    <vue3videoPlay style="pointer-events: none !important;" :src="`${baseUrl}/${item.file.filePath}`" ref="videoPlay"
                                        id="myvideo"
                                        :controlBtns="['audioTrack', 'quality', 'speedRate', 'volume', 'setting', 'pip', 'fullScreen']"
                                         />
                                </div>

                                <div v-for="(item,index) in logyPoliticsData?.csGreatCountryCraftsmanList" :key="index" style="margin-bottom:20px;">
                                    <div v-if="item.gccType == 2">
                                        <div style="display:flex;justify-content:space-between">
                                            <h5 style="height: 16px;
                                            font-size: 16px;
                                            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                                            font-weight: 500;
                                            color: #333333;
                                            line-height: 24px;margin-bottom:16px;cursor:pointer;" @click="muluClick(item)">{{ item.title }}</h5>
                                        </div>
                                        <a :href="item.content" target="_blank">{{item.content}}</a>
                                    </div>
                                    <div v-if="item.gccType == 1">
                                        <div style="display:flex;justify-content:space-between">
                                            <h5 style="height: 16px;
                                            font-size: 16px;
                                            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                                            font-weight: 500;
                                            color: #333333;
                                            line-height: 24px;margin-bottom:16px;cursor:pointer;">{{ item.title }}</h5>
                                        </div>
                                        <div v-html="item.content"></div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <el-dialog title="课程评价" v-model="dialogVisible" width="30%" :modal="false" :before-close="handleClose">

            <el-form ref="Form" :model="loginForm" label-width="100px">
                <el-form-item label="课程满意度:">
                    <el-rate :allow-half="true" style="margin-top: 10px;" v-model="loginForm.satisfaction"
                        @change="changeRate"></el-rate>
                </el-form-item>
                <el-form-item label="课程评价:">
                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" v-model="loginForm.desc"></el-input>
                </el-form-item>
            </el-form>

            <div style="text-align: center;">
                <el-button round style="margin-left: 10px;" @click="handleClose">取消</el-button>
                <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;"
                    @click="scoreClick">提交</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed, watch, getCurrentInstance, ref, onBeforeMount, } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import { backUrl } from "@/js/util"
import { baseUrl } from '@/js/util.js'
import 'vue3-video-play/dist/style.css'
import vue3videoPlay from 'vue3-video-play'
import { f_get_courseIdeologyPoliticsDataInfo, get_courseDataInfo, F_Insert_browsingHistory, get_courseTeacherTeamList, get_courseEvaluationPageList, get_courseCatalogueList, F_insert_courseEvaluation, get_studentCourseList, F_Insert_subscribeCourse } from "@/js/homeListApi.js"
import { ElMessage } from 'element-plus';

export default {
    components: {vue3videoPlay},
    setup() {
        const router = useRouter();
        const store = useStore();
        const that = getCurrentInstance();
        const state = reactive({
            videoPlay:null,
            baseUrl: baseUrl,
            userId: getStorage('userId'),
            StudentOrTeacher: getStorage('StudentOrTeacher'),
            activeName: 'first',
            activeNames: ['0'],
            dialogVisible: false,
            courObject: {},
            loginForm: {
                satisfaction: null,
                desc: ''
            },
            courseId: getStorage('courseId'),
            userScore: [],//评价
            courseItem: {},//课程详情数据
            CourseManager: [],//课程负责人
            CurriculumTeacher: [],//课程教师
            logyPoliticsData: [],//课程思政
            pageIndex: 1,
            pageSize: 10,
            num: 2,
            CourseCatalog: [],//课程目录
            ifcourse: false,
            GotoclassID: getStorage('GotoclassID'),

        })

        onMounted(() => {
            methods.getcourseItem();

            methods.getEvaluationList();
            methods.getCourseCataList();
            methods.getTeamList();
            methods.getcourseIdeologyPoliticsDataInfo();

            methods.browsingHistory();
        })

        const methods = {
            muluClick(item) {
                if (item.gccType == 2) {
                    window.open(item.content, "_blank")
                }
            },
            getcourseIdeologyPoliticsDataInfo() {
                let datas = {
                    courseId: state.courseId, //课程id

                }
                f_get_courseIdeologyPoliticsDataInfo(datas).then((res) => {
                    if (res.data.code == 1) {
                        state.logyPoliticsData = res.data.data;
                        // console.log('石星雨', state.logyPoliticsData?.showContent)
                    } else {
                        ElMessage.error(res.data.message)
                    }
                })
            },
            //获取详情基本数据
            getcourseItem() {
                let datas = {
                    courseId: state.courseId, //课程id
                    userId: getStorage('userId'), //用户id
                }
                console.log(state.courseId);
                get_courseDataInfo(datas).then((res) => {
                    if (res.data.code == 1) {
                        console.log(res.data.data, '课程详情');
                        state.courseItem = res.data.data
                        console.log(state.courseItem, 'state.courseItem');
                        // setStorage('courseItem',courseItem)
                        // router.push('/home/courseDetailsCopy')
                        methods.getstudentCourseList();
                    } else {
                        ElMessage.error(res.data.message)
                    }
                })
            },
            //获取课程详情下的教师团队
            getTeamList() {
                let datas = {
                    courseId: state.courseId,
                }
                get_courseTeacherTeamList(datas).then((res) => {
                    if (res.data.code == 1) {
                        res.data.data.forEach(item => {
                            if (item.teacherType == 1) {
                                state.CourseManager.push(item)
                            } else {
                                state.CurriculumTeacher.push(item)
                            }
                        });
                        console.log(state.CourseManager, '课程负责人');
                        console.log(state.CurriculumTeacher, '课程教师');
                    } else {
                        ElMessage.error(res.data.message)
                    }
                })
            },
            //获取课程详情下的课程评价
            getEvaluationList() {
                let datas = {
                    courseId: state.courseId,
                    pageIndex: state.pageIndex,
                    pageSize: state.pageSize,
                }
                get_courseEvaluationPageList(datas).then((res) => {
                    if (res.data.code == 1) {
                        state.userScore = res.data.data.list
                        console.log(res.data.data.list, '课程评价');
                    }
                })
            },
            //点击弹出评价弹窗
            wantEvaluate() {
                state.loginForm.satisfaction = null;
                state.loginForm.desc = '';
                state.dialogVisible = true;
            },
            //点击提交课程评价
            scoreClick() {
                if (state.loginForm.satisfaction == null) {
                    ElMessage.error('请评分')
                } else if (state.loginForm.desc == '') {
                    ElMessage.error('请输入评价')
                } else {
                    let datas = {
                        courseId: state.courseId,
                        score: state.loginForm.satisfaction,
                        content: state.loginForm.desc,
                        userId: getStorage('userId'),
                    }
                    F_insert_courseEvaluation(datas).then((res) => {
                        if (res.data.code == 1) {
                            ElMessage.success(res.data.message)
                            state.dialogVisible = false;
                            methods.getEvaluationList();
                        } else {
                            ElMessage.error(res.data.message)
                        }
                    })
                }
            },
            //点击取消
            handleClose() {
                state.dialogVisible = false;
            },
            //获取课程详情下的课程目录
            getCourseCataList() {
                let datas = {
                    courseId: state.courseId,
                    userId: getStorage('userId'),
                }
                console.log(datas);
                get_courseCatalogueList(datas).then((res) => {
                    if (res.data.code == 1) {
                        state.CourseCatalog = res.data.data
                        // console.log(res.data.data,'课程目录');
                    }
                })
            },
            //获取学生所有订阅课程
            getstudentCourseList() {
                console.log(state.StudentOrTeacher);
                if (state.StudentOrTeacher == 2) {
                    let datas = {
                        studentId: getStorage('userId'),
                    }
                    get_studentCourseList(datas).then((res) => {
                        if (res.data.code == 1) {
                            let arr = []
                            console.log(state.courseItem);
                            res.data.data.forEach((item) => {
                                if (item.courseId == state.courseItem.id) {
                                    arr.push(item)
                                    state.courObject = item;
                                }
                            })
                            if (!arr.length == 0) {
                                state.ifcourse = true;
                            } else {
                                state.ifcourse = false;
                            }
                            console.log(res.data.data, '学生所有订阅课程');
                            console.log(arr, '订阅');
                        }
                    })
                }
            },
            //点击加入选修
            JoinAnElective(courseItemID) {
                let datas = {
                    courseId: courseItemID,
                    courseType: 2,
                    studentId: getStorage('userId'),
                }
                F_Insert_subscribeCourse(datas).then((res) => {
                    if (res.data.code == 1) {
                        ElMessage.success(res.data.message)
                        methods.getstudentCourseList();
                    } else {
                        ElMessage.error(res.data.message)
                    }
                })
            },
            // 取消加入选修
            cancellation(courseItemID) {
                let datas = {
                    courseId: courseItemID,
                    studentId: getStorage('userId'),
                }
                F_Insert_subscribeCourse(datas).then((res) => {
                    if (res.data.code == 1) {
                        ElMessage.success(res.data.message)
                        methods.getstudentCourseList();
                    } else {
                        ElMessage.error(res.data.message)
                    }
                })
            },
            //进入此页面增加一次浏览量
            browsingHistory() {
                let datas = {
                    courseId: state.courseId,
                    allCount: 0,
                    monthCount: 1,
                }
                F_Insert_browsingHistory(datas).then((res) => {
                    if (res.data.code == 1) {
                        console.log('添加该课程浏览量成功');
                    }
                })
            },


            //点击课程目录下的课程 跳转到 课程目录页面
            WatchCourse(itemChild) {
                console.log(itemChild.id);

                setStorage('CourseCatalog', state.CourseCatalog); 
                router.push({ path: '/home/courseIntroductionDetails', query: { itemChildID: itemChild.id }})
            },
            //点击课程跳转课程目录页面
            courseIntroductionDetails() {
                setStorage('courseType', state.courseItem.courseType)
                setStorage('ifcourse', state.ifcourse)
                setStorage('CourseCatalog', state.CourseCatalog);
                router.push('/home/courseIntroductionDetails');
            },

            indexClick() {
                store.commit("SetnarbarMenuActive", 1);
                setStorage("narbarMenuActive", 1);
                router.push('/home/index');
            },
            courseClick() {
                router.push('/home/courseTeaching');
            },
            handleClick() {

            },
            handleChange() {

            },
            changeRate(e) {
                console.log(state.loginForm);
                // let instance  = getCurrentInstance()
                // that.ctx.$forceUpdate()
            },


            // courseIntroductionDetails(){
            //     router.push('/home/courseIntroductionDetails')
            // },
        }
        // watch(state.GotoclassID, (newVal, oldVal) => {
        //     state.courseId = newVal
        //     console.log(newVal,'state.courseId');
        // }, { deep: true })
        // watch(state.courseId, (newVal, oldVal) => {
        //     methods.getcourseItem();
        // }, { deep: true })
        return {
            ...toRefs(state),
            ...methods,

        }
    }
}

</script>
<style  lang="scss" scoped>
#courseDetails {
    height: 100%;
    position: relative;
    background: #F7F8FA;

    .box {
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
    }

    .breadCrumbs {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
    }

    .boxTwo {
        width: 100%;
        height: 250px;
        background: #fff;
        border-radius: 5px;
        display: flex;
        justify-content: space-evenly;
    }

    .boxThree {
        width: 100%;
        border-radius: 5px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
    }

    .count {
        width: 23%;
        height: 100px;
        background: #fff;
        border-radius: 5px;
    }

    .boxFour {
        width: 100%;
        height: 550px;
        border-radius: 5px;
        margin-top: 10px;
        background: #fff;
    }

    .demo-tabs {
        padding: 10px 15px 10px 15px;

        :deep .el-tabs__item {
            font-size: 18px !important;
        }
    }

    :deep .el-collapse-item__header {
        background: #F7F8FA;
    }

    :deep .el-collapse-item__wrap {
        border: none;
    }

    .scoreClass::-webkit-scrollbar {
        display: none;
    }
}
</style>
<style lang="scss">
.el-avatar>img {
    /* display: block; */
    height: 100%;
    width: 100%;
    vertical-align: middle;
}</style>